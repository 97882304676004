define("mgw/models/powerbi-export", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    exportedBy: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    exportedAt: _emberData.default.attr('date', {
      readOnly: true
    }),
    project: _emberData.default.belongsTo('project'),
    description: _emberData.default.attr('string'),
    query: _emberData.default.attr('string'),
    content: _emberData.default.attr('string')
  });

  _exports.default = _default;
});