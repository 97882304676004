define("mgw/components/export-powerbi", ["exports", "mgw/components/popover-base"], function (_exports, _popoverBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    classNames: 'modal modal--centered',
    tagName: 'form',
    description: null,
    actions: {
      submit: function submit() {
        this.get('eventservice').sendEvent('exported-to-powerbi');
        this.get('delegate').send(this.get('callback'), this.description, this.get('options.context'));
        this.send('close');
        return false;
      },
      cancel: function cancel() {
        this.send('close');
        return false;
      }
    }
  });

  _exports.default = _default;
});