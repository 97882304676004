define("mgw/templates/components/export-powerbi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KpoRWQsW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"bar bar--light flex space-between bar--nav paper-shadow\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"bar__title\"],[12],[1,[30,[36,0],[\"exportToPowerBI\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bar__btn\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"cancel\"],null],[12],[1,[30,[36,0],[\"cancel\"],null]],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn bar__btn\"],[14,4,\"submit\"],[12],[1,[30,[36,0],[\"copyToClipboard\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"input\"],[15,2,[34,2,[\"user\",\"email\"]]],[14,3,\"username\"],[14,\"autocomplete\",\"username\"],[14,\"placeholder\",\"—\"],[14,0,\"display-none\"],[14,4,\"text\"],[12],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"flex column form relative flex-grow\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,1,\"form__row__title\"],[14,0,\"form__row form__row--big\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"form__label--wide\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"description\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"value\",\"placeholder\",\"class\",\"autoresize\"],[[35,3],\"For your own reference\",\"form__input form__input--row primary-color-placeholder flex-grow align-self-center\",true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-help flex row\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"icon icon--help margin-0 flex-shrink-0\"],[14,4,\"button\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"margin-left-15 margin-bottom-11\"],[12],[2,\"\\n    \"],[10,\"pre\"],[14,0,\"margin-bottom-11\"],[12],[2,[30,[36,0],[\"exportPowerbiHelp\"],null]],[13],[2,\"\\n    \"],[10,\"a\"],[15,6,[30,[36,0],[\"powerBIGuideUrl\"],null]],[14,\"target\",\"_blank\"],[12],[1,[30,[36,0],[\"readMore\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"currentUser\",\"description\",\"input\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/export-powerbi.hbs"
    }
  });

  _exports.default = _default;
});