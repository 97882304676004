define("mgw/components/popover-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = window.$;

  var _default = Ember.Component.extend({
    deviceservice: Ember.inject.service(),
    popoverservice: Ember.inject.service(),
    // state
    loaded: true,
    // input
    popover: {},
    data: Ember.computed.alias('popover.data'),
    callback: Ember.computed.alias('popover.callback'),
    delegate: Ember.computed.alias('popover.delegate'),
    sender: Ember.computed.alias('popover.sender'),
    popoverClass: Ember.computed.alias('popover.popoverClass'),
    options: Ember.computed.alias('popover.options'),
    shouldCloseOnResize: Ember.computed('deviceservice.mobilePortrait', function () {
      if (this.get('preventCloseOnResize')) return false;
      if (this.get('popoverClass') && this.get('popoverClass').includes('popover--floating')) return true;
      if (this.get('popoverClass') === 'popover--spotlight' && this.get('deviceservice.mobilePortrait')) return true;
      return false;
    }),
    didInsertElement: function didInsertElement() {
      this.onKeydownBound = this.onKeydown.bind(this);
      $('body').on('keydown', this.onKeydownBound);
      this.handleResizeBound = this.handleResize.bind(this);
      $(window).on('resize', this.handleResizeBound);

      if (!['popover--floating', 'popover--spotlight', 'popover--spotlight--mobilePortrait', 'popover--media-viewer'].includes(this.get('popoverClass'))) {
        $('<div class="overlay"></div>').insertBefore($(this.element));
      }
    },
    handleResize: function handleResize() {
      var _this = this;

      Ember.run.next(function () {
        if (!_this.get('isDestroyed')) {
          if (_this.get('shouldCloseOnResize')) _this.send('close');
        }
      });
    },
    onKeydown: function onKeydown(e) {
      if (this.get('isDestroyed')) return;
      var keyCode = e.keyCode || e.charCode;
      if (keyCode === 27) this.send('pressedEscape', e);
    },
    submit: function submit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.send('submit');
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      $('body').off('keydown');
      this.onKeydownBound = null;
      $('window').off('resize', this.handleResizeBound);
    },
    actions: {
      close: function close() {
        var _this2 = this;

        Ember.run.next(function () {
          _this2.get('popoverservice').closePopover(_this2.get('popover'));
        });
        return false;
      },
      pressedEnter: function pressedEnter(e) {
        if ($('textarea:focus') && $('textarea:focus').length) return false;

        try {
          this.send('submit');
        } catch (_unused) {
          console.debug("Could not send submit action to popover");
        }

        e.preventDefault();
        e.stopPropagation();
        return false;
      },
      pressedEscape: function pressedEscape(e) {
        if ($('textarea:focus') && $('textarea:focus').length || $('textarea:focus') && $('textarea:focus').length) return false;

        try {
          this.send('cancel');
        } catch (_unused2) {
          this.send('close');
        }

        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    }
  });

  _exports.default = _default;
});